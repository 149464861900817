import * as React from 'react'
import ShareButton from "./shareButton"
import '../style/result.css'
import Quote from '../images/quote.svg'

const Result = ({percentage, label, translations, language, id}) => (
  <>
    <div className="sectionThreeBlock">
      <div>
        {
          percentage ? (<h3 className="percentage">{percentage}<span
            className="perc">%</span></h3>) : ''
        }
      </div>

      <span className={'resultLines'}>
        <img className="quoteLeft" src={Quote}/>
        {label}
        <img className="quoteRight" src={Quote}/>
      </span>

    </div>
    <ShareButton id={id} translations={translations} language={language}
                 st={'buttonBox'}/>
  </>
)

export default Result
