import * as React from 'react'
import * as qs from "query-string";
import HeaderBar from "../components/headerBar";
import ScoreResult from "../components/scoreResult";
import Result from "../components/result";
import '../style/style.css'
import FooterBar from "../components/footerBar";
import {graphql} from "gatsby";
import {globalHistory} from '@reach/router';
import SEO from "../components/seo"

export default function IndexPage({data}) {
  const urlParameterObj = qs.parse(globalHistory.location.search);
  const allMarkdownRemark = data.themes;

  let translations = data.translations;

  translations = translations.edges.map((translation) => {
    return translation.node.frontmatter;
  }).reduce((acc, cur) => {
    acc[cur.slug] = cur;

    return acc;
  }, {});

  const score = urlParameterObj['score'];
  const average = urlParameterObj['avg'];
  const numberOfUsers = urlParameterObj['total'];
  let languageLower = urlParameterObj['lang'];

  if (!languageLower) {
    languageLower = 'nl'
  }

  // @ts-ignore
  let language = languageLower.charAt(0).toUpperCase() + languageLower.slice(1);
  let link = '//www.stadspiratie.be/' + languageLower;

  let ownThemes = allMarkdownRemark.edges.filter((backendEntry) => {
    return Object.values(urlParameterObj).find((urlParameterValue) => {
      return backendEntry.node.frontmatter.slug === urlParameterValue;
    })
  });

  ownThemes = ownThemes.map((theme) => theme.node.frontmatter);

  return (
    <div className={'pageWrapper'}>
      <SEO title="Stadspiratie" />
      <HeaderBar siteTitle={translations.title['label' + language]} link={link}></HeaderBar>

      <div className="background">
        <section className="headSection">
          <ScoreResult translations={translations} language={language}
                       score={score} averageScore={average}></ScoreResult>

          <div className="sectionBlock">
            <h2 className="subtitle">
              {translations.titleOwn['label' + language]}
            </h2>

            {
              ownThemes.map((val) => {
                return <Result
                  translations={translations}
                  language={language}
                  percentage={null}
                  id={val.id}
                  label={val['label' + language]}
                  key={val.slug}></Result>
              })
            }
          </div>
        </section>
      </div>
      <FooterBar translations={translations}
                 language={language} numberOfUsers={numberOfUsers}/>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    themes:allMarkdownRemark {
      edges {
        node {
          frontmatter {
            id
            slug
            labelNl
            labelEn
            labelFr
          }
        }
      }
    }
    translations:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(translation)/.*\\\\.md$/"}}) {
      edges {
        node {
          frontmatter {
            slug
            labelNl
            labelEn
            labelFr
          }
        }
      }
    }
  }

`;


