import * as React from 'react'
import Bxl from '../images/bxl.svg'
import Twitter from '../images/twitter.svg'
import Facebook from '../images/facebook.svg'
import Mail from '../images/mail.svg'
import '../style/footerBar.css'

const FooterBar = ({numberOfUsers, translations, language}) => (
  <section className="footerSection">
    <div className="footerBox">

      <div className="footerMap">
        <img src={Bxl}/>
        <p className="numberOfUsers"><b>{numberOfUsers}</b></p>
      </div>

      <div>
        <div>
          <p className="endResult">
            <b>{numberOfUsers} {translations.footerBold['label' + language]}</b> {translations.footer['label' + language]}
          </p>
        </div>

        <div className="iconSelection">
          <a target={'_blank'}
             href="https://www.facebook.com/dialog/share?display=popup&redirect_uri=https://www.stadspiratie.be&app_id=2594506700781089">
            <img src={Facebook}/>
          </a>

          <a target={'_blank'}
             href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.stadspiratie.be%2F&text=">
            <img src={Twitter}/>
          </a>

          <a target={'_blank'} href={'mailto:stadspiratie@vgc.be?Subject=' + encodeURIComponent(translations.emailSubject['label' + language]) + "&body=" + encodeURIComponent(translations.emailBody['label' + language])}>
            <img src={Mail}/>
          </a>
        </div>
      </div>

    </div>
    <div className="footerImage"></div>
  </section>
)

export default FooterBar
