import * as React from 'react'
import Heart from '../images/heart.svg'
import "../style/scoreResult.css"

const ScoreResult = ({score, averageScore, translations, language}) => (
  <div className="sectionBlock">
    <h2 className="subtitle">
      {translations.titleScore['label' + language]}
    </h2>
    <div className="resultBox">
      <div className="result">
        <img src={Heart}/>
        <p className="score">{score}/10</p>
      </div>
      <p className="resultBoxCopy">
        {parseInt(averageScore) < parseInt(score) ? translations.scoreMore['label' + language] : ''}
        {averageScore && (parseInt(averageScore) === parseInt(score)) ? translations.scoreSame['label' + language] : ''}
        {parseInt(averageScore) > parseInt(score) ? translations.scoreLower['label' + language] : ''}
      </p>
    </div>
    {averageScore ? (
    <p className="averageScore">{translations.otherAverage['label' + language]} : <b>{averageScore}/10</b></p>
      ) : ''
    }
  </div>
)

export default ScoreResult
