import * as React from 'react'
import Idea from '../images/idea.svg'
import "../style/shareButton.css"

const ShareButton = ({st,  translations, language, id}) => (
  <a href={'https://www.stadspiratie.be/' + language.toLowerCase() + '/topics/' + id} className={st}>
    <img src={Idea}/>
    <span className="shareIdea">{translations.share['label' + language]}</span>
  </a>
)

export default ShareButton
